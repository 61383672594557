import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import playIcon from '../images/play.png';

class Video extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            progress: 0,
            muted: false,
        };
    }

    handleVideoPlay = e => {
        const { playing } = this.state;

        e.preventDefault();

        this.setState({
            playing: !playing,
        });
    };

    handleProgress = progress => {
        this.setState({
            progress: progress.played * 100,
        });
    };

    handleVolume = e => {
        const { muted } = this.state;

        e.preventDefault();

        this.setState({
            muted: !muted,
        });
    };

    render() {
        const { playing, progress, muted } = this.state;

        const { url, poster } = this.props;

        return (
            <div className="video-player">
                { !playing && (
                    <a
                        className="btn-play-lg"
                        href="#"
                        onClick={this.handleVideoPlay}
                    >
                        <img src={playIcon} alt="play" />
                    </a>
                ) }
                <div onClick={ this.handleVideoPlay }>
                    <ReactPlayer
                        url={ url }
                        controls={ false }
                        playing={ playing }
                        onProgress={ this.handleProgress }
                        width="100%"
                        height="100%"
                        volume={ 1 }
                        muted={ muted }
                        fileConfig={{ attributes: { poster: poster } }}
                    />
                </div>
                <div className="video-controls">
                    <div className="play-control">
                        <a
                            href="#"
                            onClick={ this.handleVideoPlay }
                            className={
                                !playing ? 'btn-play' : 'btn-play btn-pause'
                            }
                        >
                            Play/Pause
                        </a>
                    </div>
                    <div className="progress-bar">
                        <div
                            className="time-bar"
                            style={{
                                width: `${progress}%`,
                                transition: 'width 1s linear',
                            }}
                        >
                            &nbsp;
                        </div>
                    </div>

                    <a
                        href="#"
                        onClick={ this.handleVolume }
                        className={ muted ? 'volume muted' : 'volume' }
                    >
                        Mute/Unmute
                    </a>
                </div>
            </div>
        );
    }
}

export default Video;
