import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Video from '../components/video';

const Page = ({ data }) => {
    const doc = data.allPrismicPageTemplate.edges.slice(0, 1).pop();
    const id = doc.node.uid;
    const node = doc.node.dataRaw;
    const nodeData = doc.node.data;
    const leftContent = nodeData.left_content_copy;
    const rightContent = nodeData.right_content_copy;
    const video = nodeData.video;
    const videoTitle = nodeData.video_title;
    const videoPoster = nodeData.video_image;
    const rightImage = nodeData.right_content_image;
    const bgImage = nodeData.background_image;
    const clients = nodeData.client_list;

    let contacts = [];
    let links = [];
    let footer_popups = [];

    if (nodeData.body.length > 0) {
        contacts = nodeData.body[0].items;
    }

    if (node.body2.length > 0) {
        links = node.body2[0].items.map(link => link);
    }

    if (node.body3.length > 0) {
        footer_popups = nodeData.body3[0].items.map(item => item);
    }

    return (
        <Layout id={ id } secondaryLinks={ links }>
            <main className="main">
                { bgImage.url && (
                    <div
                        className="background-image"
                        style={
                            bgImage.url
                                ? { backgroundImage: `url(${ bgImage.url })` }
                                : {}
                        }
                    ></div>
                ) }
                <div className="container container__content-right">
                    { leftContent.html !== '<p></p>' && (
                        <div className="content content__left">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: leftContent.html,
                                }}
                            />
                        </div>
                    ) }
                    <div className="content content__right">
                        { rightContent.html !== '' && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: rightContent.html,
                                }}
                            />
                        ) }
                        { clients.html !== '' && (
                            <div className="clients-list">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: clients.html,
                                    }}
                                />
                            </div>
                        ) }
                        { contacts.length > 0 &&
                            contacts.map(item => (
                                <div className="contact-details">
                                    <div
                                        className="grey-text"
                                        dangerouslySetInnerHTML={{
                                            __html: item.description.html,
                                        }}
                                    />
                                    <div
                                        className="lg-text"
                                        dangerouslySetInnerHTML={{
                                            __html: item.contact_address.html,
                                        }}
                                    />
                                </div>
                            )) }
                        { rightImage.url && (
                            <img
                                src={ rightImage.url }
                                alt={ rightImage.alt }
                                className="max-width-100"
                            />
                        ) }
                        { video.url && (
                            <div className="right_video">
                                <h3 className="video_title">
                                    { videoTitle.text }
                                </h3>
                                <Video
                                    url={ video.url }
                                    poster={ videoPoster.url }
                                />
                            </div>
                        ) }
                    </div>
                </div>
            </main>
            {nodeData.show_footer === 'Yes' && (
                <Footer popups={ footer_popups } />
            )}
        </Layout>
    );
};

export const query = graphql`
    query PageTemplateSlug($uid: String) {
        allPrismicPageTemplate(filter: { uid: { eq: $uid } }) {
            edges {
                node {
                    id
                    uid
                    prismicId
                    dataRaw
                    data {
                        background_image {
                            url
                        }
                        left_content_copy {
                            html
                        }
                        right_content_copy {
                            html
                        }
                        right_content_image {
                            url
                            alt
                            localFile {
                                id
                            }
                        }
                        video {
                            url
                        }
                        video_image {
                            url
                        }
                        video_title {
                            text
                        }
                        client_list {
                            html
                        }
                        body {
                            ... on PrismicPageTemplateBodyContactAddress {
                                id
                                items {
                                    contact_address {
                                        html
                                    }
                                    description {
                                        html
                                    }
                                }
                            }
                        }
                        show_footer
                        body3 {
                            ... on PrismicPageTemplateBody3FooterPopUp {
                                items {
                                    link_text {
                                        text
                                    }
                                    pop_up_content {
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default withPreview(Page);
