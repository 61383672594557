import React from 'react';
import PopUp from './popup';

const Footer = ({ popups }) => (
    <footer>
        <div id="top_footer">
            <p>Rae Creative Ltd</p>
        </div>
        <div id="mid_footer">
            { popups.map(popup => {
                return (
                    <PopUp
                        link_text={popup.link_text.text}
                        content={popup.pop_up_content.html}
                    />
                );
            }) }
        </div>
        <div className="clearfloat" id="bottom_footer">
            <p>
                All image and content @{ new Date().getFullYear() } Rae Creative
                Ltd
            </p>
        </div>
    </footer>
);

export default Footer;
