import React, { PureComponent } from 'react';
import Modal from 'react-modal';

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '532px',
        paddingTop: '10px',
        paddingRight: '30px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        background: '#ddd',
        textAlign: 'center',
    },
};

class PopUp extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({ isModalOpen: false });
    }

    render() {
        const { link_text, content } = this.props;
        const { isModalOpen } = this.state;

        return (
            <div className="popup_item">
                <button
                    onClick={() => this.setState({ isModalOpen: !isModalOpen })}
                    className="popup_link"
                >
                    {link_text}
                </button>
                <Modal
                    isOpen={isModalOpen}
                    style={modalStyles}
                    onRequestClose={this.closeModal}
                >
                    <span className="exit" onClick={this.closeModal}>
                        x
                    </span>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </Modal>
            </div>
        );
    }
}

export default PopUp;
