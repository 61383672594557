import { Link } from 'gatsby';
import React, { PureComponent } from 'react';

class Navigation extends PureComponent {
    renderSecondaryNavigation = () => (
        <ul className="main-nav-second-tier">
            { this.props.secondaryLinks.map(link => (
                <li>
                    <Link
                        to={link.link.slug}
                        className="main-nav__link"
                        activeClassName="active"
                    >
                        { link.text[0].text }
                    </Link>
                </li>
            )) }
        </ul>
    );

    render() {
        const { hidden } = this.props;
        const navClasses = `main-nav ${hidden ? 'main-nav--hidden' : ''}`;

        return (
            <nav className={ navClasses }>
                <ul className="main-nav__list">
                    <li className="main-nav__item">
                        <Link
                            to="/about"
                            className="main-nav__link"
                            activeClassName="main-nav__link main-nav__link--active"
                        >
                            About
                        </Link>
                    </li>
                    <li className="main-nav__item">
                        <Link
                            to="/collection-management"
                            className="main-nav__link"
                            activeClassName="main-nav__link main-nav__link--active"
                        >
                            Collection Management
                        </Link>
                    </li>
                    <li className="main-nav__item">
                        <Link
                            to="/creative-logistics"
                            className="main-nav__link"
                            activeClassName="main-nav__link main-nav__link--active"
                        >
                            Creative Logistics
                        </Link>
                    </li>
                    <li className="main-nav__item">
                        <Link
                            to="/clients"
                            className="main-nav__link"
                            activeClassName="main-nav__link main-nav__link--active"
                        >
                            Clients
                        </Link>
                    </li>
                    <li className="main-nav__item">
                        <Link
                            to="/contact"
                            className="main-nav__link"
                            activeClassName="main-nav__link main-nav__link--active"
                        >
                            Contact
                        </Link>
                    </li>
                    <li className="main-nav__icon">
                        <a
                            href="https://www.instagram.com/rae_creative"
                            className="icon-instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    </li>
                </ul>

                { this.props.secondaryLinks && this.renderSecondaryNavigation() }
            </nav>
        );
    }
}

export default Navigation;
