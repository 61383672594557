import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Navigation from './navigation';

class Header extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuHidden: true,
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        const { mobileMenuHidden } = this.state;

        this.setState({
            mobileMenuHidden: !mobileMenuHidden,
        });
    }

    render() {
        const { siteTitle } = this.props;
        const { mobileMenuHidden } = this.state;

        return (
            <header className="main-header">
                <div className="header">
                    <div className="header-links">
                        <div className="mobile-menu" onClick={this.toggleMenu}>
                            Menu
                        </div>
                    </div>
                    <h1 className="rc-logo">
                        <Link to="/" className="rc-logo__link">
                            { siteTitle }
                        </Link>
                    </h1>
                </div>

                <Navigation { ...this.props } hidden={mobileMenuHidden} />
            </header>
        );
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
